<template>
  <b-card>
    <section>
      <div class="d-flex justify-content-start flex-wrap">
        <b-button
          variant="adn"
          data-action-type="new"
          class="mr-25 mb-1"
          @click="
            (v) => {
              openModal();
            }
          "
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-25"
          />
          {{ $t('uploadStudentsFromExcelFile') }}
        </b-button>

        <b-button
          variant="outline-adn"
          class="ml-50 mb-1"
          data-action-type="new"
          @click="
            (v) => {
              downloadExcelSheet();
            }"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-25"
          />
          {{ $t('downloadExcelSheet') }}
        </b-button>
      </div>
    </section>

    <b-modal
      ref="edit-modal"
      no-close-on-backdrop
      centered
      size="lg"
      hide-footer
      :title="$t('exportNewStudents')"
    >
      <g-form @submit="uploadItems">
        <b-row>
          <b-col cols="12">
            <div>
              <input
                accept=".xlsx, .xls"
                type="file"
                class="excel-upload-input"
                @change="handleFileUpload( $event )"
              >
              <b-alert
                v-if="file !== ''"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1"
              type="submit"
              variant="gradient-primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              class="mx-1"
              variant="outline-primary"
              @click="closeModal"
            >
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>

      </g-form>
    </b-modal>

    <b-row>
      <b-button
        v-permission="'viewStudents'"
        class="btn-filter"
        variant="adn"
        @click=" () => {
          showFilter = !showFilter;
          ResetSelection();
        }"
      >
        {{ !showFilter ? this.$t('showFilter') : this.$t('hideFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="danger"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilter && !hideActions"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="studentName"
          v-silent-search
          :value.sync="filter.studentName"
          label-text="studentName"
          name="studentName"
          @keydown.enter="() => { refreshItems();}
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.stageId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="stage"
          field="select"
          :options="stages"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.rowId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="row"
          field="select"
          :options="rows"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.classroomId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="class"
          field="select"
          :options="classrooms"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="dateLabel font-small-3"
          for="example-datepicker"
        > {{ $t('registrationDateFrom') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.registrationDateFrom"
          locale="ar"
          :date-format-options=" {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="registrationDate"
          @input="() => {
            refreshItems();
          }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="dateLabel font-small-3"
          for="example-datepicker"
        > {{ $t('registrationDateTo') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.registrationDateTo"
          locale="ar"
          :date-format-options=" {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="registrationDate"
          @input="() => {
            refreshItems();
          }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.nationalityId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="nationality"
          field="select"
          :options="nationalities"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="identityNumber"
          v-silent-search
          :value.sync="filter.identityNumber"
          label-text="IDNumber"
          name="identityNumber"
          @keydown.enter="() => { refreshItems();}
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="mobileNumber"
          v-silent-search
          :value.sync="filter.mobileNumber"
          label-text="mobile"
          name="mobileNumber"
          @keydown.enter="() => { refreshItems();}
          "
        />
      </b-col>

      <!-- group type selection -->
      <b-col
        class="gb-package"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("suspensionSitautation") }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', { btnActive: suspensionCase === 'unSuspended' }]"
            variant="flat-warning"
            @click="() => {
              suspensionCase = 'unSuspended'
              refreshItems()
            }"
          >
            {{ $t("unSuspended") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: suspensionCase === 'suspended' }]"
            variant="flat-warning"
            @click="() => {
              suspensionCase = 'suspended'
              refreshItems()
            }"
          >
            {{ $t("suspended") }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <b-row align-h="between" class="mb-1 mt-1">
      <!-- Search -->
      <!-- <b-col cols="12" md="4">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            :clearable="true"
            :placeholder="$t('search')"
          />
        </div>
      </b-col> -->

      <b-col
        md="12"
        class="list-buttons d-flex align-items-center justify-content-end mb-1 mb-md-0"
      >
        <b-button
          variant="primary"
          v-permission="'addStudents'"
          data-action-type="new"
          @click="
            (v) => {
              $router.push({ name: 'student-new' });
            }
          "
        >
          {{ $t("new") }}
        </b-button>
        <vue-excel-xlsx
          :data="excelDataItems"
          :columns="excelColumnsItems"
          :filename="this.$route.name"
          :sheetname="'xlsxSheet'"
          class="btn btn-relief-success ml-1 p-0 ptn-sm"
        >
          <img
            src="@/assets/images/icons/xls.jpg"
            alt=""
            style="height: 39px; width: auto"
          />
        </vue-excel-xlsx>
        <b-button
          variant="relief-danger"
          class="ml-1 p-0 ptn-sm"
          @click="printDocument"
        >
          <img
            src="@/assets/images/icons/pdf.jpg"
            alt=""
            style="height: 39px; width: auto"
          />
        </b-button>
      </b-col>
    </b-row>
    <g-table
      ref="students-table"
      :columns="tableColumns"
      :items="itemsProvider"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :filter="searchQuery"
      :totalRows="totalRows"
      class="imageTable"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template #fullName="{ item }">
        <div class="d-table align-items-start">
          <b-avatar
            size="40"
            :src="`${baseUrl}${item.imageUrl}`"
            variant="primary"
          >
          </b-avatar>
          {{ item.fullName }}
        </div>
      </template>
      <template
        v-if="!hideActions"
        #actions="{ item }"
      >
        <div class="text-nowrap">
          <b-button
            v-permission="'viewStudents'"
            v-b-tooltip.hover.top="$t('printStudentApplicationForm')"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="printStudentApplicationForm(item)"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mx-1 clickable"
              stroke="cornflowerblue"
            />
          </b-button>
          <b-button
            data-action-type="edit"
            v-permission="'editStudents'"
            v-b-tooltip.hover.top="$t('edit')"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              icon="EditIcon"
              class="clickable"
              stroke="slategray"
              :id="`invoice-row-${item.id}-preview-icon`"
            />
          </b-button>
          <b-button
            v-if="!item.isSuspended"
            data-action-type="edit"
            v-b-tooltip.hover.top="$t('createCollectionTransaction')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission="'addCollectionTransactions'"
            @click="createCollectionTransaction(item)"
          >
            <feather-icon
              icon="CreditCardIcon"
              class="mx-1 clickable"
              stroke="orange"
              :id="`invoice-row-${item.id}-fileText-icon`"
            />
          </b-button>

          <b-button
            v-if="!item.isSuspended"
            data-action-type="edit"
            v-b-tooltip.hover.top="$t('createNewInvoice')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission="'addSalesTransactions'"
            @click="createInvoice(item)"
          >
            <feather-icon
              icon="ShoppingBagIcon"
              class="clickable"
              stroke="green"
              :id="`invoice-row-${item.id}-fileText-icon`"
            />
          </b-button>

          <b-button
            data-action-type="edit"
            v-b-tooltip.hover.top="$t('showStudentAccountStatement')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission="'accountsStudentreports'"
            @click="showStudentAccountStatment(item)"
          >
            <feather-icon
              icon="FileTextIcon"
              class="mx-1 clickable"
              stroke="purple"
              :id="`invoice-row-${item.id}-fileText-icon`"
            />
          </b-button>

          <b-button
            data-action-type="delete"
            v-permission="'deleteStudents'"
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import saveAs from "file-saver";
import GTable from "@/pages/Shared/Table.vue";
import reportMixin from '@/mixin/reportMixin';

export default {
  mixins: [reportMixin],
  components: {
    GTable,
  },
  data() {
    return {
      fileFromBE: "",
      file: "",
      searchQuery: "",
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      perPage: 25,
      filterOn: [],
      filter: {
        orderClause: null,
        studentName: null,
        stageId: null,
        rowId: null,
        classroomId: null,
        registrationDateFrom: null,
        registrationDateTo: null,
        nationalityId: null,
        identityNumber: null,
        mobileNumber: null,
        isSuspended: false
      },
      isSortDirDesc: false,
      sortDirection: "asc",
      pathName: this.$route.name,
      itemsArray: [],
      hideActions: false,
      stages: [],
      classrooms: [],
      rows: [],
      nationalities: [],
      showFilter: false,
      fiscalYearStart: null,
      fiscalYearEnd: null,

      studentToPrint: {
        studentId: null,
        fromDate: null,
        toDate: null
      },

      excelDataItems: [],
      suspensionCase: 'unSuspended'
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: "code",
          field: "code",
          label: this.$t("code"),
          sortable: true,
        },
        {
          key: "fullName",
          field: "fullName",
          label: this.$t("name"),
          sortable: true,
        },
        {
          key: this.isRight ? "guardianNameAr" : "guardianNameEn",
          field: this.isRight ? "guardianNameAr" : "guardianNameEn",
          label: this.$t("guardians"),
          sortable: true,
        },
        {
          key: this.isRight ? "stageNameAr" : "stageNameEn",
          field: this.isRight ? "stageNameAr" : "stageNameEn",
          label: this.$t("stage"),
          sortable: true,
        },
        {
          key: this.isRight ? "rowNameAr" : "rowNameEn",
          field: this.isRight ? "rowNameAr" : "rowNameEn",
          label: this.$t("row"),
          sortable: true,
        },
        {
          key: this.isRight ? "classroomNameAr" : "classroomNameEn",
          field: this.isRight ? "classroomNameAr" : "classroomNameEn",
          label: this.$t("class"),
          sortable: true,
        },
        { key: "actions" },
      ];
    },

    excelColumnsItems() {
      return [
        { key: "code", field: "code", label: this.$t("code")},
        { key: "arabicName", field: "arabicName", label: this.$t("arabicName")},
        { key: "englishName", field: "englishName", label: this.$t("englishName")},
        { key: "birthDate", field: "birthDate", label: this.$t("birthDate")},
        { key: "hijriBirthDate", field: "hijriBirthDate", label: this.$t("hijriBirthDate")},
        { key: "registrationDate", field: "registrationDate", label: this.$t("registrationDate")},
        { key: "hijriRegistrationDate", field: "hijriRegistrationDate", label: this.$t("hijriRegistrationDate")},
        { key: "identityNumber", field: "identityNumber", label: this.$t("IDNumber")},
        { key: "mobileNumber", field: "mobileNumber", label: this.$t("phone")},
        { key: "isMobileNumberTextable", field: "isMobileNumberTextable", label: this.$t("mobileNumberTextable")},
        { key: "isRegisteredInNoor", field: "isRegisteredInNoor", label: this.$t("registeredInNoor")},
        { key: "noorId", field: "noorId", label: this.$t("noorId")},
        { key: "accountingLinkage", field: "accountingLinkage", label: this.$t("accountingLinkage")},
        { key: "guardianName", field: "guardianName", label: this.$t("guardian")},
        { key: "guardianRelationName", field: "guardianRelationName", label: this.$t("guardianRelationship")},
        { key: "genderName", field: "genderName", label: this.$t("gender")},
        { key: "nameOfTheLastSchool", field: "nameOfTheLastSchool", label: this.$t("nameOfTheLastSchool")},
        { key: "nationalityName", field: "nationalityName", label: this.$t("nationality")},
        { key: "previousBalance", field: "previousBalance", label: this.$t("previousBalance")},
        { key: "busArrivalAddress", field: "busArrivalAddress", label: this.$t("arrivalStation")},
        { key: "busSubscriptionCategoryName", field: "busSubscriptionCategoryName", label: this.$t("busSubscriptionCategory")},
        { key: "isCompletedPapers", field: "isCompletedPapers", label: this.$t("completedPapers")},
        { key: "notes", field: "notes", label: this.$t("notes")},
      ]
    }
  },
  watch: {
    'filter.registrationDateFrom'(newVal) {
      if (newVal > this.filter.registrationDateTo) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    },
    'filter.registrationDateTo'(newVal) {
      if (this.filter.registrationDateFrom > newVal) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    }
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.get({ url: 'nationalities' }).then((data) => {
        this.nationalities = data;
      });
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
      this.get({ url: 'Rows' }).then((data) => {
        this.rows = data;
      });
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classrooms = data;
      });
    },

    getForExcel() {
      var params = this.getFilterObj(this.filter);
      this.get({ url: `students/get-for-excel?${params}` }).then((data) => {
        this.excelDataItems = data;
        this.excelDataItems.forEach(element => {
          element.birthDate = this.getDate(element.birthDate);
          element.registrationDate = this.getDate(element.registrationDate);
          element.isMobileNumberTextable = element.isMobileNumberTextable ? 'نعم' : 'لا'
          element.isRegisteredInNoor = element.isRegisteredInNoor ? 'نعم' : 'لا'
          element.hasBus = element.hasBus ? 'نعم' : 'لا'
          element.isCompletedPapers = element.isCompletedPapers ? 'نعم' : 'لا'
        });
      });
    },

    ResetSelection() {
        if (!this.showFilter) {
          this.filter.registrationDateFrom = this.fiscalYearStart;
          this.filter.registrationDateTo = this.fiscalYearEnd;
          this.filter.studentName = null;
          this.filter.stageId = null;
          this.filter.rowId = null;
          this.filter.classroomId = null;
          this.filter.nationalityId = null;
          this.filter.identityNumber = null;
          this.filter.mobileNumber = null;
          this.suspensionCase = 'unSuspended';
          this.refreshItems();
        }
      },

    createCollectionTransaction(item) {
      this.$router.push({
        name: 'collection-transactions-new',
        query: { studentId: item.id },
        params: {name: item.arabicName}
      });
    },

    createInvoice(item) {
      this.$router.push({
        name: 'pos-invoice',
        query: { studentId: item.id},
        params: {name: item.arabicName}
      });
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    showStudentAccountStatment(item) {
      this.studentToPrint.studentId = item.id
      this.studentToPrint.isSuspended = item.isSuspended
      this.studentToPrint.balanceDiscription = this.$t('allValuesForPreviousBalance')
      this.printReport(this.isRight ? 'StudentsAccountStatement-ar' : 'StudentsAccountStatement-en', this.studentToPrint);
    },

    hide() {
      this.hideActions = true;
    },

    pdfExport(name) {
      html2canvas(document.getElementById("mainTable")).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },

    printDocument() {
      this.hideActions = true;
      setTimeout(() => {
        this.pdfExport(this.$route.name);
        this.hideActions = false;
      }, 500);
    },

    setFilertForSuspension() {
      this.filter.isSuspended = this.suspensionCase === 'suspended'
    },

    itemsProvider(ctx, callback) {
      const { currentPage, perPage, sortBy, sortDesc } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      this.setFilertForSuspension();

      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;

      this.get({ url: `Students${params}` }).then((data) => {
          this.isTableBusy = false;
          this.totalRows = data.totalRecords;
          data.pageData.forEach((item) => {
            item.fullName = this.isRight ? item.arabicName : item.englishName;
            const rowsName = this.rows.find((val) => val.id === item.row) || {
              arabicName: "",
              englishName: "",
            };
            item.rowArabicName = rowsName.arabicName;
            item.imageUrl = item.imageUrl ? item.imageUrl : 'Student_default_image.jpg'
          });
          callback(data.pageData);
          this.itemsArray = data.pageData;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });

      this.getForExcel();
      return null;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    refreshItems() {
      this.$refs["students-table"].refreshTable();
    },

    remove(item) {
      this.confirmAction(
        {
          text: this.$t("areYouSureYouWantToDelete"),
        },
        () => {
          this.delete({
            url: "students",
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t("deletedSuccessfully") });
            this.refreshItems();
          });
        }
      );
    },

    edit(item) {
      this.$router.push({
        name: "student-edit",
        params: { id: item.id },
      });
    },

    closeModal() {
      this.$refs['edit-modal'].hide();
      if (this.file)
        this.refreshItems();
    },

    openModal() {
      this.$refs['edit-modal'].show();
      this.file = ''
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (!this.isExcel(this.file)) {
        this.file = '';
        this.doneAlert({ text: this.$t('Only supports upload .xlsx, .xls, .csv suffix files'), type: 'error' });
        return false
      }
    },

    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },

    uploadItems() {
      if (this.file === '' || !this.file.name) {
        this.doneAlert({ text: this.$t('addFileFirst'), type: 'warning' });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.create({ url: 'students/data-migration', data: formData })
      .then(() => {
        this.closeModal();
        this.doneAlert({ text: this.$t('savedSuccessfully')});
      });
    },

    downloadExcelSheet() {
      this.get({ url: 'students/data-migration', responseType: 'arraybuffer' }).then((data) => {
        saveAs(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Students.xlsx');
      });
    },

    printStudentApplicationForm(item) {
      const studentToPrint = {
        id: item.id
      }
      this.printReport(this.isRight ? 'StudentRegisterationForm-ar' : 'StudentRegisterationForm-en', studentToPrint);
    }
  },
};
</script>

<style>
@media (max-width: 768px) {
 .list-buttons {
    margin-top: 1.5rem;
 }
}

.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border-radius: 7px;
  border-top-left-radius: 0;
  border: 2px solid #ad8884;
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: -3px;
}

.gb-package label {
  font-size: 14px;
  margin-bottom: 10px;
}

b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2)
}
</style>
